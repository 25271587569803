var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v(_vm._s(_vm.formTitle)),
      ]),
      _c("vx-card", [
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Currency Code*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:3|max:3",
                    expression: "'required|min:3|max:3'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Currency Code", disabled: _vm.currencyId > 0 },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "code", $$v)
                  },
                  expression: "form.code",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Currency Code"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Currency Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:25",
                    expression: "'required|max:25'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Currency Name" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Currency Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Smallest Denominatinon*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|decimal",
                    expression: "'required|decimal'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  min: "0",
                  name: "Smallest Denominatinon",
                },
                model: {
                  value: _vm.form.smallestDenomination,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "smallestDenomination", _vm._n($$v))
                  },
                  expression: "form.smallestDenomination",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Smallest Denominatinon"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Available Stock")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min_value:0",
                    expression: "'min_value:0'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  min: "0",
                  step: "1",
                  name: "Available Stock",
                },
                model: {
                  value: _vm.form.availableStock,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "availableStock", _vm._n($$v))
                  },
                  expression: "form.availableStock",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Available Stock"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Min. Amount For Boost")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "decimal|min_value:0",
                    expression: "'decimal|min_value:0'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  min: "0",
                  name: "Minimum Amount For Boost",
                },
                model: {
                  value: _vm.form.minAmountForBoost,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "minAmountForBoost", _vm._n($$v))
                  },
                  expression: "form.minAmountForBoost",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Minimum Amount For Boost"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Markup For Sell Rate ")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|decimal|min_value:0",
                    expression: "'required|decimal|min_value:0'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  min: "0",
                  name: "Markup For Sell Rate",
                },
                model: {
                  value: _vm.form.sellMarkup,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sellMarkup", _vm._n($$v))
                  },
                  expression: "form.sellMarkup",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Markup For Sell Rate"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Markup For Boost Rate ")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|decimal|min_value:0",
                    expression: "'required|decimal|min_value:0'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  min: "0",
                  name: "Markup For Boost Rate ",
                },
                model: {
                  value: _vm.form.boostMarkup,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "boostMarkup", _vm._n($$v))
                  },
                  expression: "form.boostMarkup",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Markup For Boost Rate "))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Active")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
            [
              _c("vs-switch", {
                model: {
                  value: _vm.form.isActive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isActive", $$v)
                  },
                  expression: "form.isActive",
                },
              }),
            ],
            1
          ),
        ]),
        _vm.currencyId
          ? _c("div", { staticClass: "vx-row mb-4" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("API Rate")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "number",
                      min: "0",
                      disabled: "",
                      name: "API Rate",
                    },
                    model: {
                      value: _vm.form.apiRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "apiRate", _vm._n($$v))
                      },
                      expression: "form.apiRate",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.currencyId
          ? _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("API Rate Last Updated")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { disabled: "", name: "API Rate Last Updated" },
                    model: {
                      value: _vm.form.apiRateLastUpdatedDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "apiRateLastUpdatedDate", $$v)
                      },
                      expression: "form.apiRateLastUpdatedDate",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "danger", type: "filled" },
                on: { click: _vm.onCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-2",
                attrs: { color: "primary", type: "filled" },
                on: { click: _vm.onSave },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }